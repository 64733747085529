<template>
  <div
    v-if="authenticated && me && statistics"
    class="h-100 account-page bg-page-dark d-flex"
    style="overflow: hidden"
  >
    <div style="overflow: auto" class="h-100 b-white w-100 d-flex flex-column">
      <b-container class="p-0 avatar">
        <div class="d-flex p-2 justify-content-between">
          <div>
            <b-link :to="{ name: 'list' }">
              <span class="align-self-start btn btn-sm btn-outline-primary">
                <font-awesome-icon icon="arrow-left" fixed-width />
              </span>
              Retour à la liste des restaurants
            </b-link>
          </div>
          <div class="d-flex">
            <p class="text-center mb-0">{{ me.name }} {{ me.firstName }}</p>
          </div>
        </div>
      </b-container>
      <b-container class="mt-3 pt-3 bg-white flex-fill shadow-lg border">
        <b-row no-gutters class="mb-3">
          <b-col cols="12" class="p-1" offset-md="3" md="6">
            <div class="d-flex align-items-center">
              <img src="../../assets/img/logo-ici-on-cuisine.png" height="66" />
              <h4 class="font-weight-bold ml-4 mb-0">
                Bienvenue sur le site icioncuisine.com
              </h4>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col cols="6" class="p-1" offset-md="2" offset-lg="3" md="4" lg="3">
            <b-button
              :to="{ name: 'user-venue-deals' }"
              variant="primary"
              class="d-flex align-items-center text-left text-md-center"
              block
            >
              <span class="count pr-2">{{
                statistics.userVenueDealCount
              }}</span>
              Bons Plans utilisés
              <font-awesome-icon
                icon="chevron-right"
                fixed-width
                size="2x"
                class="ml-auto"
              />
            </b-button>
          </b-col>
          <b-col cols="6" class="p-1" md="4" lg="3">
            <b-button
              variant="primary"
              class="d-flex align-items-center text-left text-md-center"
              block
              :to="{ name: 'booking' }"
            >
              <span class="count pr-2">{{ statistics.reservationsCount }}</span>
              Réservations effectuées
              <font-awesome-icon
                icon="chevron-right"
                fixed-width
                size="2x"
                class="ml-auto"
              />
            </b-button>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="3" md="6">
            <b-button
              variant="primary"
              class="d-flex align-items-center text-left text-md-center"
              block
              :to="{ name: 'account-bookmarks' }"
            >
              <span class="count pr-2">{{ statistics.bookmarksCount }}</span>
              Restaurants favoris
              <font-awesome-icon
                icon="chevron-right"
                fixed-width
                size="2x"
                class="ml-auto"
              />
            </b-button>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="3" md="6">
            <b-button
              variant="light"
              class="d-flex align-items-center text-left text-md-center"
              block
              :to="{ name: 'account-to-try' }"
            >
              <span class="count pr-2">{{ statistics.toTryCount }}</span> Lieux
              à tester
              <font-awesome-icon
                icon="chevron-right"
                fixed-width
                size="2x"
                class="ml-auto"
              />
            </b-button>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="3" md="6">
            <b-button
              variant="success"
              class="d-flex align-items-center text-center text-md-center"
              block
              href="https://icioncuisine.com/vignerons"
            >
              <font-awesome-icon icon="shopping-cart" fixed-width size="2x" />
              <span style="font-size: 1rem" class="mx-auto">
                Achat direct petits vignerons vin et champagne
              </span>
              <font-awesome-icon
                icon="chevron-right"
                fixed-width
                size="2x"
                class="ml-auto"
              />
            </b-button>
            <small style="font-size: 10px; line-height: 10px">
              en cliquant sur ce bouton vous attestez avoir plus+ de 18 ans en
              France ou l’âge autorisé à acheter de l’alcool dans votre pays
            </small>
          </b-col>
        </b-row>
        <b-row class="mt-4" no-gutters>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link :to="{ name: 'profile' }"> Editer mes informations </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link :to="{ name: 'phone' }">
              Modifier mon numéro de téléphone
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link :to="{ name: 'notifications' }"> Notifications </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link
              href="mailto:contact@du-jour.fr?subject=Site icioncuisine.com"
            >
              Aide / Signaler un bug
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link href="https://dujour.pro" target="_blank">
              Restaurateur
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link
              href="mailto:com@du-jour.fr?subject=Demande d'informations sur le parrainage des Bons Plans"
            >
              Annonceur
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link href="https://mdj.to/legals" target="_blank">
              Politique de confidentialité
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link href="https://mdj.to/about" target="_blank">
              A propos
            </b-link>
          </b-col>
          <b-col cols="12" class="p-1" offset-md="5" md="4">
            <b-link @click.prevent="logout"> Déconnexion </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <transition name="swipe-left">
      <over-router-view
        v-if="isChild()"
        @canceldeal="() => --statistics.userVenueDealCount"
      />
    </transition>
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <b-btn variant="primary" @click="login()">
      Se connecter ou s'inscrire
    </b-btn>
  </div>
</template>
<script>
import STATISTICS from "@/graphql/auth/statistics.gql"
import OverRouterView from "@/components/OverRouterView"
import Me from "@/mixins/me"
export default {
  components: {
    OverRouterView
  },
  mixins: [Me],
  computed: {
    style: function () {
      if (this.isChild()) {
        return {
          overflow: "hidden"
        }
      }
      return {}
    }
  },
  apollo: {
    statistics: {
      query: STATISTICS,
      skip: true,
      client: "auth"
    }
  },
  mounted() {
    this.$nextTick(() => this.login())
  },
  methods: {
    login() {
      this.$login
        .login()
        .then(() => {
          this.$apollo.queries.me.skip = false
          this.$apollo.queries.me.refetch()
          this.$apollo.queries.statistics.skip = false
          this.$apollo.queries.statistics.refetch()
        })
        .catch(() => {})
    },
    logout() {
      this.$login.logout().then(response => {
        this.$router.push({ name: "list" })
      })
    },
    isChild() {
      const childs = [
        "settings",
        "email",
        "profile",
        "notifications",
        "phone",
        "iban",
        "checkins",
        "booking",
        "credits",
        "credit-venue",
        "credit-authorised-venue",
        "credit-captured-venue",
        "credit-captured-venue",
        "booking-venue",
        "credits-captured",
        "credits-authorized",
        "checkin-venue",
        "account-bookmarks",
        "account-bookmark-venue",
        "user-venue-deals-venue",
        "user-venue-deals",
        "account-to-try",
        "account-to-try-venue",
        "booking-reservation"
      ]
      return childs.includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  white-space: normal;
}
.router-view {
  top: 0;
  left: 0;
  overflow: auto;
}
.count {
  font-size: 2rem;
  font-weight: bold;
}
</style>
